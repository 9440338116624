<template>
  <v-container fluid pa-0 fill-height>
    <v-row no-gutters class="fill-height">
      <v-col cols="12" >
        <devices-list></devices-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DevicesList from "../components/deviceControl/devicesList";

export default {
  components: {
    DevicesList,
  },
  data() {
    return {
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // access to component instance via `vm`
    });
  },
  beforeCreate() {},
  mounted() {
  }
};
</script>
