<template>
  <v-container fluid pa-1>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card flat tile>
          <v-card-title class="secondary">
            <span>Device Control</span>
            <v-spacer></v-spacer>
            <v-text-field
              flat
              label="Search Meter"
              prepend-inner-icon="search"
              hide-details
              solo
              v-model="searchMeter"
              clearable
              @click:clear="searchMeter = ''"
            ></v-text-field>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn class="ml-2" icon color="green" v-on="on" @click="filterMeters('false')">
                  <v-icon dark>check_circle</v-icon>
                </v-btn>
              </template>
              <span>Filter online meters</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn class="ml-2" icon color="red" v-on="on" @click="filterMeters('true')">
                  <v-icon dark>close</v-icon>
                </v-btn>
              </template>
              <span>Filter offline meters</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn class="ml-2" icon color="purple" v-on="on" @click="filterMeters('')">
                  <v-icon dark>filter_none</v-icon>
                </v-btn>
              </template>
              <span>Clear filters</span>
            </v-tooltip>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters class="pa-2">
      <v-col cols="12">
        <v-row align="start" justify="start">
          <v-card v-for="(meter) in filteredmeters" :key="meter.id" class="ma-2 pa-0">
            <v-card-text class="pa-2 text-center black--text">
              <p>
                <!-- <v-icon class="mr-2" color="orange">flash_on</v-icon> -->
                {{meter.name}}
              </p>
              <v-divider class="mb-1"></v-divider>
              <p>{{ meter.serial_number }}</p>
              <p>{{ meter.meterkind.family}}</p>
              <p>{{ meter.meterkind.kind_name}}</p>
              <p>{{ meter.gateway.name }}</p>
            </v-card-text>
            <v-card-actions class="pa-0">
              <v-btn
                v-if="meter.isOffline == 'false'"
                class="green"
                tile
                block
                @click="showToggleRelayDialog(meter, false)"
              >Toggle Relay</v-btn>
              <v-btn
                v-else
                class="red"
                tile
                block
                disabled
                @click="showToggleRelayDialog(meter, true)"
              >Toggle Relay</v-btn>
            </v-card-actions>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="toggleRelayDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Please confirm your action{{selectedMeter.serial_number}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="toggleRelay">Yes, Turn Off</v-btn>
          <v-btn color="green darken-1" text @click="toggleRelayDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { EventBus } from "@/lib/EventBus";
import apiHelper from "../../apiHelper";
export default {
  name: "devices-list",
  components: {
  },
  data() {
    return {
      searchMeter: "",
      filter: "",
      toggleRelayDialog: false,
      selectedMeter: {},
      toggleRelayAction: null
    };
  },
  computed: {
    meters() {
      return this.$store.getters["MDMStore/getterMeters"];
    },
    filteredmeters() {
      if (this.searchMeter == "" && this.filter == "") return this.meters;
      else if (this.searchMeter != "") {
        return this.meters.filter(
          item =>
            item.serial_number
              .toLowerCase()
              .includes(this.searchMeter.toLowerCase()) ||
            item.name.toLowerCase().includes(this.searchMeter.toLowerCase())
        );
      } else if (this.filter != "") {
        return this.meters.filter(item => item.isOffline == this.filter);
      }
    }
  },
  beforeCreate() {},
  mounted() {
    this.$store.dispatch({
      type: "MDMStore/getMeters",
      orgId: localStorage.getItem("orgId")
    });
    this.$store.dispatch("MDMStore/getMeterKinds");
    EventBus.$on("filter-meter", filter => {
      this.filter = filter;
    });
  },
  methods: {
    filterMeters(isOffline) {
      this.filter = isOffline;
      isOffline == ""
        ? (this.searchMeter = "")
        : (this.searchMeter = this.searchMeter);
    },
    meterKindName(id) {
      return this.$store.getters["MDMStore/getterMeterKindName"](id);
    },
    gatewayName(id) {
      return this.$store.getters["MDMStore/getterGatewayName"](id);
    },
    showToggleRelayDialog(meter, action) {
      this.toggleRelayDialog = true;
      this.selectedMeter = meter;
      this.toggleRelayAction = action;
    },
    async toggleRelay() {

      try {
        // if (this.getCPValue() != null && this.getCPValue() != undefined) {
        var response = await apiHelper(
          "POST",
          "mdm/gateway/" +
            this.selectedMeter.gateway.serial_number +
            "/" +
            this.selectedMeter.serial_number +
            "/realtime",
          {
            cp: "switch",
            value: this.toggleRelayAction
          }
        );
        if(response){
          this.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {text:"Relay toggled successfully", status:"success"});
        }
        // var response = await API.post(
        //   "gateway/" + this._props.gatewayId + "/" + meter.serial_number + "/realtime",
        //   {
        //     cp: this.getCPValue(),
        //     value: status
        //   }
        // );
        // }
        this.toggleRelayDialog = false;
        this.selectedMeter = {};
      } catch (response) {
        this.toggleRelayDialog = false;
      }
    }
  }
};
</script>
